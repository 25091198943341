import {cloneElement, createContext, useState} from 'react'
import {nanoid} from 'nanoid'

export const ModalManagerContext = createContext()

export default function ModalManager({children}) {
    const [modals, setModals] = useState([])

    const openModal = modal => {
        const {promise, resolve} = Promise.withResolvers()
        const key = nanoid()

        const afterClose = () => {
            setModals(modals => modals.filter(m => m.key !== key))
        }

        const close = () => {
            setModals(modals => modals.map(
                m => {
                    if (m.key === key) {
                        return clone(m, {open: false, visible: false})
                    }
                    else {
                        return m
                    }
                })
            )
        }

        const onCancel = () => {
            close()
            resolve()
        }

        const onOk = result => {
            close()
            resolve(result)
        }

        const clone = (e, modalProps) => {
            const _clone = (e, modalProps) => {
                if (e.type._context) {
                    return cloneElement(e, {
                        children: _clone(e.props.children, modalProps)
                    })
                }
                else {
                    return cloneElement(e, {
                        afterClose,
                        destroyOnClose: true,
                        onCancel,
                        onOk,
                        ...modalProps,
                    })
                }
            }

            return cloneElement(_clone(e, modalProps), {key})
        }

        const m = (
            <ModalManagerContext.Provider value={openModal}>
                {modal}
            </ModalManagerContext.Provider>
        )

        setModals(modals => [
            ...modals,
            clone(m, {open: true, visible: true})
        ])

        return promise
    }

    return (
        <>
            <ModalManagerContext.Provider value={openModal}>
                {children}
            </ModalManagerContext.Provider>

            {modals}
        </>
    )
}
