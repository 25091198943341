import {
    createContext,
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'

import {css} from '@emotion/react'
import {nanoid} from 'nanoid'

const PopoverContext = createContext()

export const usePopover = () => useContext(PopoverContext)

const cssPopover = css({
    position: 'fixed',
    zIndex: 2000,
})

const Popover = ({placement = 'center', x, y, ...props}) => {
    const refEl = useRef()

    useLayoutEffect(
        () => {
            const {innerWidth: vw, innerHeight: vh} = window
            const {clientWidth: w, clientHeight: h} = refEl.current

            const left = (() => {
                if (vw < w) {
                    return 0
                }
                else if (/\bleft$/.test(placement)) {
                    return 0 < x - w ? x - w : 0
                }
                else if (/\bright$/.test(placement)) {
                    return vw < x + w ? vw - w : x
                }
                else {
                    return 0 < x - w / 2 ? x - w / 2 : 0
                }
            })()

            const top = (() => {
                if (vh < h) {
                    return 0
                }
                else if (/^top\b/.test(placement)) {
                    return 0 < y - h ? y - h : 0
                }
                else if (/^bottom\b/.test(placement)) {
                    return vh < y + h ? vh - h : y
                }
                else {
                    return 0 < y - h / 2 ? y - h / 2 : 0
                }
            })()

            Object.assign(refEl.current.style, {
                top: `${top}px`,
                left: `${left}px`,
            })
        },

        [placement, x, y]
    )

    return (
        <div
            ref={refEl}
            css={cssPopover}
            {...props}
        ></div>
    )
}

export function PopoverManager({children}) {
    const refContainer = useRef()
    const [chain, setChain] = useState([])

    useEffect(
        () => {
            const handleMouseDown = ({target}) => {
                setChain(chain => {
                    const triggers = new Set(
                        chain.map(e => e.trigger)
                    )

                    let p = target

                    while (p) {
                        if (
                            p === refContainer.current ||
                            triggers.has(p)
                        ) {
                            return chain
                        }
                        else {
                            p = p.parentElement
                        }
                    }

                    return []
                })
            }

            window.addEventListener('mousedown', handleMouseDown, true)

            return () => {
                window.removeEventListener('mousedown', handleMouseDown, true)
            }
        },

        []
    )

    const createHandler = (level) => {
        /**
         * close self
         */
        const close = () => {
            setChain(chain => chain.slice(0, level - 1))
        }

        /**
         * open or close a child popover
         */
        const open = (content, options) => {
            // open a child popover
            if (content) {
                const handler = createHandler(level + 1)
                const key = nanoid()
                const {x, y, placement, trigger} = options

                const popover = (
                    <Popover
                        key={key}
                        placement={placement}
                        x={x}
                        y={y}
                    >
                        <PopoverContext.Provider value={handler}>
                            {content}
                        </PopoverContext.Provider>
                    </Popover>
                )

                setChain(chain => [
                    ...chain.slice(0, level),
                    {key, popover, trigger}
                ])
            }
            // close child popover
            else {
                setChain(chain => chain.slice(0, level + 1))
            }
        }

        if (level) {
            return {close, open}
        }
        else {
            return {open}
        }
    }

    return (
        <PopoverContext.Provider value={createHandler(0)}>
            {children}

            <div ref={refContainer}>
                {chain.map(e => e.popover)}
            </div>
        </PopoverContext.Provider>
    )
}
