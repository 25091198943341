import {css} from '@emotion/react'
import {Input} from 'antd'
import {useReadOnly} from './ReadOnly.mjs'
import useInputable from './useInputable.mjs'

const cssInput = css({
    '& textarea': {
        backgroundColor: 'inherit',
        color: 'inherit',
        textAlign: 'inherit',
    },
})

const TextArea = ({
    allowClear,
    changeDelay,
    focus,
    maxLength,
    parse,
    readOnly,
    selectOnFocus,
    test,
    transform,
    value,
    onChange,
    onBlur,
    onInput,
    ...props
}) => {
    const gReadOnly = useReadOnly()

    const {
        callbackRef: inputableCallbackRef,
        handleInput,
        handleClick,
        handleCompositionEnd,
        handleCompositionStart,
        handleBlur,
        inputValue,
    } = useInputable({
        allowClear,
        changeDelay,
        focus,
        parse,
        readOnly: readOnly || gReadOnly,
        selectOnFocus,
        test,
        transform,
        value,
        onChange,
        onBlur,
        onInput,
    })

    // BUGFIX: Input.TextArea 不支持 maxLength
    const callbackRef = (el) => {
        inputableCallbackRef(el)

        if (el && (maxLength || 0 === maxLength)) {
            const elInput = el.querySelector('textarea')
            elInput.maxLength = maxLength
        }
    }

    return (
        <div
            ref={callbackRef}
            css={cssInput}
            style={{display: 'contents'}}
            onClick={handleClick}
        >
            <Input.TextArea
                allowClear={allowClear}
                autoSize
                value={inputValue}
                onBlur={handleBlur}
                onCompositionEnd={handleCompositionEnd}
                onCompositionStart={handleCompositionStart}
                onInput={handleInput}
                {...props}
            />
        </div>
    )
}

export default TextArea
