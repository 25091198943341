import {useMemo} from 'react'
import {nanoid} from 'nanoid'
import xlsx from 'xlsx-js-style'
import merge from '@/script/merge.mjs'
import {writeFileToDisk} from '@/script/file.mjs'
import getColumnValue from './getColumnValue.mjs'
import useConfig from './useConfig.mjs'
import useRows from './useRows.mjs'
import useRowSelection from './useRowSelection.mjs'

const defaultDataSource = []

const useTable = ({
    config: cfg,
    configurable = Boolean(cfg),
    columns,
    dataSource = defaultDataSource,
    expandable: {childrenColumnName = 'children'} = {},
    getRowNum,
    rowKey = '$id',
    rowSelection: rs,
    setRowNum,
    table,
    beforeChange = (rows, oldRows) => rows,
    beforeCreateRow = (row) => row,
    beforeDeleteRow = (row) => true,
    beforeUpdateRow = (row, oldRow) => row,
    onChange = (rows, oldRows) => {},
    onChangeConfig,
}) => {
    const tableConfig = useConfig(columns, cfg, onChangeConfig)

    const iterRows = function* (rows) {
        for (const row of rows) {
            yield row
            const children = row[childrenColumnName]

            if (children) {
                yield* iterRows(children)
            }
        }
    }

    const {
        clearFilters,
        clearSorts,
        filters,
        hasFilter,
        hasSort,
        refinedRows,
        rows,
        setFilter,
        setRowKeyToIndex,
        setSort,
        sorts,
    } = useRows({childrenColumnName, dataSource, rowKey, setRowNum})

    const [
        rowSelection,
        selectRows
    ] = useRowSelection(rs, refinedRows, rowKey, iterRows)

    const keyToIndex = useMemo(
        () => new Map(
            rows.map(
                ({[rowKey]: key}, index) => [key, index]
            )
        ),

        [rows]
    )

    const keyToRefinedIndex = useMemo(
        () => new Map(
            refinedRows.map(
                ({[rowKey]: key}, index) => [key, index]
            )
        ),

        [refinedRows]
    )

    const indexFromKey = (key) => keyToIndex.get(key)

    const assignKey = (row) => {
        if ('$id' === rowKey) {
            return {...row, $id: nanoid()}
        }
        else {
            return row
        }
    }

    const _onChange = async (newRows) => {
        const dataSource = await beforeChange(newRows, rows)
        onChange(dataSource, rows)
    }

    const setRows = async (rowsToSet) => {
        const rts = await Promise.all(
            rowsToSet.map((row) => {
                const rk = row[rowKey]
                const oldRow = getRow(rk)

                if (! oldRow) {
                    return beforeCreateRow(assignKey(row))
                }
                else if (oldRow !== row) {
                    return beforeUpdateRow(row)
                }
                else {
                    return row
                }
            })
        )

        setRowKeyToIndex(() => {
            const newRk2i = new Map()

            for (const [index, {[rowKey]: rk}] of rts.entries()) {
                newRk2i.set(rk, index)
            }

            return newRk2i
        })

        _onChange(rts)
    }

    const appendRows = async (rowsToCreate) => {
        if (0 === rowsToCreate.length) {
            return
        }

        const rtc = await Promise.all(
            rowsToCreate.map(
                (row) => beforeCreateRow(assignKey(row))
            )
        )

        setRowKeyToIndex((rk2i) => {
            const newRk2i = new Map(rk2i)

            for (const [index, {[rowKey]: rk}] of rtc.entries()) {
                newRk2i.set(rk, refinedRows.length + index)
            }

            return newRk2i
        })

        _onChange([...rows, ...rtc])
    }

    const prependRows = async (rowsToCreate) => {
        if (0 === rowsToCreate.length) {
            return
        }

        const rtc = await Promise.all(
            rowsToCreate.map(
                (row) => beforeCreateRow(assignKey(row))
            )
        )

        setRowKeyToIndex((rk2i) => {
            const newRk2i = new Map()

            for (const [index, {[rowKey]: rk}] of rtc.entries()) {
                newRk2i.set(rk, index)
            }

            for (const [rk, index] of rk2i.entries()) {
                newRk2i.set(rk, index + rtc.length)
            }

            return newRk2i
        })

        _onChange([...rtc, ...rows])
    }

    const deleteRows = (keys) => {
        if (0 === keys.length) {
            return
        }

        const keySet = new Set(keys)
        filterRows(({[rowKey]: key}) => ! keySet.has(key))
    }

    const filterRows = (pred) => {
        const deletedIndexes = []
        const newRows = []

        for (const row of rows) {
            if (! pred(row) && beforeDeleteRow(row)) {
                const index = keyToRefinedIndex.get(row[rowKey])

                if (-1 < index) {
                    deletedIndexes.push(index)
                }
            }
            else {
                newRows.push(row)
            }
        }

        deletedIndexes.sort((a, b) => a - b)

        setRowKeyToIndex((rk2i) => {
            const newRk2i = new Map()

            for (const [rk, index] of rk2i.entries()) {
                const i = deletedIndexes.findIndex((i) => index < i)
                const delta = -1 < i ? i : deletedIndexes.length
                newRk2i.set(rk, index - delta)
            }

            return newRk2i
        })

        _onChange(newRows)
    }

    const insertRowsAfter = async (pairs) => {
        if (0 === pairs.length) {
            return
        }

        const newRows = []
        const keyToRows = new Map(pairs)
        const insertedIndexRowsPairs = []

        for (const row of rows) {
            newRows.push(row)
            const key = row[rowKey]

            if (keyToRows.has(key)) {
                const rowsToCreate = keyToRows.get(key)

                const rtc = await Promise.all(
                    rowsToCreate.map(
                        (row) => beforeCreateRow(assignKey(row))
                    )
                )

                newRows.push(...rtc)
                const index = keyToRefinedIndex.get(key)

                if (-1 < index) {
                    insertedIndexRowsPairs.push([index + 1, rtc])
                }
            }
        }

        insertedIndexRowsPairs.sort(([ia], [ib]) => ia - ib)

        setRowKeyToIndex((rk2i) => {
            const newRk2i = new Map()
            const indexDeltaPairs = []
            let delta = 0

            for (const [index, rows] of insertedIndexRowsPairs) {
                for (const {[rowKey]: rk} of rows) {
                    newRk2i.set(rk, index + delta)
                    delta += 1
                }

                indexDeltaPairs.push([index, delta])
            }

            for (const [rk, index] of rk2i.entries()) {
                const i = indexDeltaPairs.findIndex(([i]) => index < i)

                const d = (() => {
                    if (-1 === i) {
                        return delta
                    }
                    else if (0 < i) {
                        return indexDeltaPairs[i - 1][1]
                    }
                    else {
                        return 0
                    }
                })()

                newRk2i.set(rk, index + d)
            }

            return newRk2i
        })

        _onChange(newRows)
    }

    const insertRowsBefore = async (pairs) => {
        if (0 === pairs.length) {
            return
        }

        const newRows = []
        const keyToRows = new Map(pairs)
        const insertedIndexRowsPairs = []

        for (const row of rows) {
            const key = row[rowKey]

            if (keyToRows.has(key)) {
                const rowsToCreate = keyToRows.get(key)

                const rtc = await Promise.all(
                    rowsToCreate.map(
                        (row) => beforeCreateRow(assignKey(row))
                    )
                )

                newRows.push(...rtc)
                const index = keyToRefinedIndex.get(key)

                if (-1 < index) {
                    insertedIndexRowsPairs.push([index, rtc])
                }
            }

            newRows.push(row)
        }

        insertedIndexRowsPairs.sort(([ia], [ib]) => ia - ib)

        setRowKeyToIndex((rk2i) => {
            const newRk2i = new Map()
            const indexDeltaPairs = []
            let delta = 0

            for (const [index, rows] of insertedIndexRowsPairs) {
                for (const {[rowKey]: rk} of rows) {
                    newRk2i.set(rk, index + delta)
                    delta += 1
                }

                indexDeltaPairs.push([index, delta])
            }

            for (const [rk, index] of rk2i.entries()) {
                const i = indexDeltaPairs.findIndex(([i]) => index < i)

                const d = (() => {
                    if (-1 === i) {
                        return delta
                    }
                    else if (0 < i) {
                        return indexDeltaPairs[i - 1][1]
                    }
                    else {
                        return 0
                    }
                })()

                newRk2i.set(rk, index + d)
            }

            return newRk2i
        })

        _onChange(newRows)
    }

    const updateRows = async (pairs) => {
        if (0 === pairs.length) {
            return
        }

        const keyToUpdates = new Map(pairs)

        const newRows = await Promise.all(
            rows.map((row) => {
                const key = row[rowKey]

                if (keyToUpdates.has(key)) {
                    const updates = keyToUpdates.get(key)
                    return beforeUpdateRow(merge(row, updates), row)
                }
                else {
                    return row
                }
            })
        )

        setRowKeyToIndex((rk2i) => {
            const newRk2i = new Map(rk2i)

            for (const [rk] of pairs) {
                const index = keyToRefinedIndex.get(rk)

                if (-1 < index) {
                    newRk2i.set(rk, index)
                }
            }

            return newRk2i
        })

        _onChange(newRows)
    }

    const swap = (arr, ia, ib) => {
        const a = arr[ia]
        const b = arr[ib]
        arr[ib] = a
        arr[ia] = b
    }

    const moveRowsDown = (keys) => {
        if (
            0 === keys.length ||
            0 < filters.length ||
            0 < sorts.length
        ) {
            return
        }

        const indexes = keys
            .map(indexFromKey)
            .sort((a, b) => b - a)

        const newRows = rows.slice()
        const newIndexes = new Set()

        for (const index of indexes) {
            if (
                index < newRows.length - 1 &&
                ! newIndexes.has(index + 1)
            ) {
                swap(newRows, index, index + 1)
                newIndexes.add(index + 1)
            }
            else {
                newIndexes.add(index)
            }
        }

        _onChange(newRows)
    }

    const moveRowsUp = (keys) => {
        if (
            0 === keys.length ||
            0 < filters.length ||
            0 < sorts.length
        ) {
            return
        }

        const indexes = keys
            .map(indexFromKey)
            .sort((a, b) => a - b)

        const newRows = rows.slice()
        const newIndexes = new Set()

        for (const index of indexes) {
            if (
                0 < index &&
                ! newIndexes.has(index - 1)
            ) {
                swap(newRows, index, index - 1)
                newIndexes.add(index - 1)
            }
            else {
                newIndexes.add(index)
            }
        }

        _onChange(newRows)
    }

    const reset = () => {
        clearFilters()
        clearSorts()
        selectRows([])
    }

    const getRow = (rowKey) => {
        const index = indexFromKey(rowKey)
        return rows[index]
    }

    const getSelectedRows = () => {
        if (rowSelection) {
            return rowSelection.selectedRowKeys
                .map((key) => keyToRefinedIndex.get(key))
                .sort((a, b) => a - b)
                .map((index) => refinedRows[index])
        }
        else {
            return []
        }
    }

    const exportAsXlsx = ({
        cellStyle = {
            alignment: {
                vertical: 'center',
                wrapText: true,
            },

            border: {
                top: {
                    style: 'thin',
                    color: {auto: 1},
                },

                right: {
                    style: 'thin',
                    color: {auto: 1},
                },

                bottom: {
                    style: 'thin',
                    color: {auto: 1},
                },

                left: {
                    style: 'thin',
                    color: {auto: 1},
                },
            },

            font: {
                name: 'Helvetica',
                sz: 10,
            },
        },

        suggestedName,

        titleCellStyle = {
            alignment: {
                vertical: 'center',
                horizontal: 'center',
            },

            border: {
                top: {
                    style: 'thin',
                    color: {auto: 1},
                },

                right: {
                    style: 'thin',
                    color: {auto: 1},
                },

                bottom: {
                    style: 'thin',
                    color: {auto: 1},
                },

                left: {
                    style: 'thin',
                    color: {auto: 1},
                },
            },

            font: {
                bold: true,
                name: 'Helvetica',
                sz: 10,
            }
        },

        beforeCreateSheet = (a) => a,
    }) => {
        const getColName = (column) => {
            return 'string' === typeof column.dataIndex ?
                column.dataIndex : `[${column.dataIndex}]`
        }

        const getTitleCell = (column) => {
            const title = (
                column.titleExport ??
                column.titleText ??
                column.title
            )

            if ('string' !== typeof title) {
                const colName = getColName(column)

                throw new Error(`列 ${colName} 的标题不是字符串，无法导出，请检查列的 titleExport/titleText/title 属性`)
            }

            return {
                v: title,
                t: 's',
                s: titleCellStyle,
            }
        }

        const getCell = (column, row, rowIndex) => {
            const value = column.getValue ?
                column.getValue(row)
                :
                getColumnValue(row, column.dataIndex)

            const exportValue = column.getExport ?
                column.getExport(value) : value

            if (
                undefined === exportValue ||
                null === exportValue
            ) {
                return {
                    s: cellStyle,
                    t: 's',
                    v: '',
                }
            }
            else if ('string' === typeof exportValue) {
                return {
                    s: cellStyle,
                    t: 's',
                    v: exportValue,
                }
            }
            else if ('number' === typeof exportValue) {
                return {
                    s: cellStyle,
                    t: 'n',
                    v: exportValue,
                }
            }
            else if ('boolean' === typeof exportValue) {
                return {
                    s: cellStyle,
                    t: 's',
                    v: exportValue ? '是' : '否',
                }
            }
            else if (exportValue?.v) {
                return exportValue
            }
            else {
                const colName = getColName(column)

                throw new Error(`行 ${rowIndex} 列 ${colName} 的值不是字符串/数字/布尔/CellObject类型，无法导出，请检查 getValue 和 getExport 属性`)
            }
        }

        const getRowHeightsAndColWidths = (columns, aoa) => {
            const table = document.createElement('table')

            Object.assign(table.style, {
                visibility: 'hidden',
                position: 'fixed',
                top: -9999,
                left: -9999,
                width: 'max-content',
                zIndex: -1,
            })

            const colgroup = document.createElement('colgroup')
            table.append(colgroup)

            for (const column of columns) {
                const col = document.createElement('col')
                colgroup.append(col)
            }

            for (const row of aoa) {
                const tr = document.createElement('tr')

                for (const cell of row) {
                    const td = document.createElement('td')
                    td.innerText = cell.v

                    Object.assign(td.style, {
                        padding: 0,
                        wordBreak: 'break-word',
                    })

                    const {
                        alignment: {wrapText} = {},
                        font: {bold, name, sz} = {},
                    } = cell.s

                    if (bold) {
                        td.style.fontWeight = 'bold'
                    }

                    if (name) {
                        td.style.fontFamily = name
                    }

                    if (sz) {
                        td.style.fontSize = `${sz}pt`
                    }

                    if (! wrapText) {
                        td.style.whiteSpace = 'nowrap'
                    }

                    tr.append(td)
                }

                table.append(tr)
            }

            document.body.append(table)

            const entriesCol = Array.prototype.entries.call(
                table.querySelectorAll('col')
            )

            const colWidths = []

            for (const [i, col] of entriesCol) {
                if (
                    columns[i].width &&
                    columns[i].width < col.clientWidth
                ) {
                    col.style.width = `${columns[i].width}px`
                }

                // HTML 和 EXCEL 之间的宽度有比例差距，原因未知
                const wpx = Math.round(col.clientWidth * 0.85) + 4
                colWidths.push({wpx})
            }

            const rowHeights = []

            for (const tr of table.querySelectorAll('tr')) {
                // HTML 和 EXCEL 之间的高度有比例差距，原因未知
                const hpx = Math.round(tr.clientHeight * 0.75)
                rowHeights.push({hpx})
            }

            table.remove()
            return [rowHeights, colWidths]
        }

        const saveWorkbook = (workbook) => {
            const file = xlsx.write(workbook, {
                cellStyles: true,
                type: 'array',
            })

            return writeFileToDisk(file, {
                excludeAcceptAllOption: true,
                suggestedName,

                types: [
                    {
                        description: 'Excel 2007-365 (.xlsx)',

                        accept: {
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                        },
                    },
                ],
            })
        }

        const rows = 0 < rowSelection?.selectedRowKeys?.length ?
            getSelectedRows() : refinedRows

        const aoa = Array.from(new Array(rows.length + 1)).map(() => [])

        const columnsToExport = columns.filter(
            ({getExport}) => null !== getExport
        )

        for (let i = 0; i < columnsToExport.length; i += 1) {
            aoa[0][i] = getTitleCell(columnsToExport[i])

            for (let j = 0; j < rows.length; j += 1) {
                aoa[j + 1][i] = getCell(columnsToExport[i], rows[j], j)
            }
        }

        const worksheet = xlsx.utils.aoa_to_sheet(beforeCreateSheet(aoa))

        const [rowHeights, colWidths] = getRowHeightsAndColWidths(
            columnsToExport, aoa
        )

        worksheet['!rows'] = rowHeights
        worksheet['!cols'] = colWidths
        const workbook = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(workbook, worksheet)
        return saveWorkbook(workbook)
    }

    Object.assign(table, {
        appendRows,
        configurable,
        columns,
        deleteRows,
        exportAsXlsx,
        filterRows,
        filters,
        getRow,
        getSelectedRows,
        hasFilter,
        hasSort,
        indexFromKey,
        insertRowsAfter,
        insertRowsBefore,
        iterRows,
        moveRowsDown,
        moveRowsUp,
        numFromKey: getRowNum,
        prependRows,
        refinedRows,
        //replaceRows,
        reset,
        rowKey,
        rows,
        rowSelection,
        selectRows,
        setFilter,
        setRows,
        setSort,
        sorts,
        updateRows,
        ...tableConfig,
    })
}

export default useTable
