class CustomError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;

        if ('function' === typeof Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        }
        else {
            this.stack = new Error(message).stack;
        }
    }
}

export default CustomError;
