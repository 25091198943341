import {useState} from 'react'
import {eventBus, useEventBus} from '@/script/eventBus.mjs'
import http from './http.mjs'
import {setToken} from './token.mjs'

const key = 'user'

let user = (() => {
    const item = window.localStorage.getItem(key)

    if (item) {
        return JSON.parse(item)
    }
    else {
        return null
    }
})()

export const setUser = newUser => {
    user = newUser
    eventBus.publish(key, user)
    bc.postMessage(user)

    if (newUser) {
        const item = JSON.stringify(user)
        window.localStorage.setItem(key, item)
    }
    else {
        window.localStorage.removeItem(key)
    }
}

const bc = new BroadcastChannel(key)

bc.onmessage = ({data}) => {
    user = data
    eventBus.publish(key, user)
}

export const useUser = () => {
    const [, setFlag] = useState(false)
    useEventBus(key, () => setFlag(f => ! f))
    return user
}

// 密码登录
export const login = async ({password, userNo}) => {
    const {user, ...token} = await http.post(
        '/login/token',
        {password, userNo},
        {headers: {customHeader:'123'}},
    )

    setToken(token)
    setUser(user)
}

// 验证码登录
export const login2 = async ({phone, verifyCode}) => {
    const {user, ...token} = await http.post(
        '/login/token/verifyCode',
        {phone, verifyCode},
    )

    setToken(token)
    setUser(user)
}

// 验证码登录
export const codeLogin = async ({userNo, phone, verifyCode}) => {
    const {user, ...token} = await http.post(
        '/login/findPwd/verifyCode',
        {userNo, phone, verifyCode},
    )

    setToken(token)
    setUser(user)
}

export const logout = () => {
    setToken(null)
    setUser(null)
}
