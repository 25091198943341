import {useCallback, useEffect, useRef, useState} from 'react'
import useDraft from '@/hooks/useDraft.mjs'
import useDebounce from '@/hooks/useDebounce.mjs'

const defaultParse = a => a
const defaultTest = _ => true
const nop = () => {}

const useInputable = ({
    allowClear,
    changeDelay = 0,
    focus,
    parse = defaultParse,
    readOnly = false,
    selectOnFocus = false,
    test = defaultTest,
    transform = defaultParse,
    value,
    onChange = nop,
    onBlur = nop,
    onInput = nop,
}) => {
    const refCaret = useRef(0)
    const [elInput, setElInput] = useState()

    const {
        draft: inputValue,
        setDraft: setInputValue,
        isChanged: isInputValueChanged,
        setIsChanged: setIsInputValueChanged,
    } = useDraft(
        String(value ?? ''),
        (data, draft) => ! Object.is(data, String(parse(draft) ?? ''))
    )

    const refValueBeforeComposition = useRef()

    const callbackRef = useCallback(
        (el) => {
            if (! el) {
                return
            }

            const elInput = el.querySelector('input, textarea')
            setElInput(elInput)
        },

        []
    )

    //useEffect(
        //() => {
            //if (! elInput) {
                //return
            //}

            //const handleSelectionChange = () => {
                //if (elInput !== document.activeElement) {
                    //return
                //}

                //const {selectionStart, selectionEnd} = elInput
                //refCaret.current = Math.min(selectionStart, selectionEnd)
                //console.log('change', refCaret.current)
            //}

            //document.addEventListener(
                //'selectionchange', handleSelectionChange
            //)

            //return () => document.removeEventListener(
                //'selectionchange', handleSelectionChange
            //)
        //},

        //[elInput]
    //)

    // 修复当使用 transform 功能时导致的输入过程中光标移动到末尾的问题
    useEffect(
        () => {
            if (! elInput) {
                return
            }

            const caret = refCaret.current
            elInput.setSelectionRange(caret, caret)
        },

        [elInput, inputValue]
    )

    useEffect(
        () => {
            if (! elInput) {
                return
            }

            elInput.readOnly = readOnly

            if (readOnly) {
                elInput
                    .nextElementSibling
                    ?.querySelector('.ant-input-clear-icon')
                    ?.remove()
            }
        },

        [elInput, readOnly]
    )

    useEffect(
        () => {
            if (! (elInput && selectOnFocus)) {
                return
            }

            const handleFocus = () => elInput.select()
            elInput.addEventListener('focus', handleFocus)
            return () => elInput.removeEventListener('focus', handleFocus)
        },

        [elInput]
    )

    useEffect(
        () => {
            if (focus) {
                elInput?.focus()

                elInput?.setSelectionRange(
                    Number.MAX_SAFE_INTEGER,
                    Number.MAX_SAFE_INTEGER,
                )
            }
            else {
                elInput?.blur()
            }
        },

        [elInput, focus]
    )

    const [
        debouncedOnChange,
        cancelDebouncedOnChange,
    ] = useDebounce(
        changeDelay,

        (...args) => {
            setIsInputValueChanged(false)
            onChange(...args)
        }
    )

    const handleBlur = (e) => {
        const parsedValue = parse(inputValue)

        if (isInputValueChanged) {
            cancelDebouncedOnChange()
            onChange(parsedValue)
        }

        setInputValue(parsedValue)
        onBlur?.(e)
    }

    const handleClick = (e) => {
        e.stopPropagation()

        if (! allowClear) {
            return
        }

        const elClear = e.nativeEvent.composedPath().find(
            (el) => (
                el.classList?.contains('ant-select-clear') ||
                el.classList?.contains('ant-input-clear-icon')
            )
        )

        if (elClear) {
            elInput?.focus()
            setInputValue('')
            debouncedOnChange?.('')
        }
    }

    const handleInput = (e) => {
        const {
            nativeEvent: {isComposing},
            target: {value},
        } = e

        // 修复当使用 transform 功能时导致的输入过程中光标移动到末尾的问题
        const {selectionStart, selectionEnd} = elInput
        refCaret.current = Math.min(selectionStart, selectionEnd)

        if (isComposing) {
            setInputValue(value)
        }
        else if (! value || test(value)) {
            const transformedValue = transform(value)
            setInputValue(transformedValue)

            if (null !== changeDelay) {
                const parsedValue = parse(transformedValue)
                debouncedOnChange?.(parsedValue)
            }
        }

        onInput(e)
    }

    const handleCompositionStart = () => {
        refValueBeforeComposition.current = inputValue
    }

    const handleCompositionEnd = ({target: {value}}) => {
        if (test(value)) {
            const transformedValue = transform(value)
            setInputValue(transformedValue)

            if (null !== changeDelay) {
                const parsedValue = parse(transformedValue)
                debouncedOnChange?.(parsedValue)
            }
        }
        else {
            setInputValue(refValueBeforeComposition.current)
        }
    }

    return {
        callbackRef,
        handleBlur,
        handleClick,
        handleCompositionEnd,
        handleCompositionStart,
        handleInput,
        inputValue,
        setInputValue,
    }
}

export default useInputable
