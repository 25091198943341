import {createContext, useContext, useEffect, useRef, useState} from 'react'
import {css} from '@emotion/react'
import {nanoid} from 'nanoid'
import {useDraggableListContext} from './DraggableList.jsx'

const DraggableListItemContext = createContext()

export const useDraggableListItemContext = () => {
    return useContext(DraggableListItemContext)
}

export const useDraggableListItem = refEl => {
    const list = useDraggableListContext()
    const [isFocused, setIsFocused] = useState(false)
    const refId = useRef(nanoid())

    useEffect(
        () => {
            list.registerItem(refId.current, refEl.current)

            return () => {
                list.deregisteritem(refId.current)
            }
        },

        []
    )

    const context = {
        blur: () => {
            setIsFocused(false)
        },

        focus: () => {
            setIsFocused(true)
        },

        isFocused,

        move: (x, y, movementX, movementY) => {
            list.move(refId.current, x, y, movementX, movementY)
        },

        moveEnd: () => {
            list.moveEnd(refId.current)
        },

        moveStart: (x, y) => {
            list.moveStart(refId.current, x, y)
        },
    }

    return context
}

const cssDraggableListItemFocused = css({
    position: 'relative',
    zIndex: 1,
    boxShadow: '0 0 4px rgba(0, 0, 0, .5)',
})

export default function DraggableListItem({children, ...props}) {
    const refEl = useRef()
    const context = useDraggableListItem(refEl)

    const csss = []

    if (context.isFocused) {
        csss.push(cssDraggableListItemFocused)
    }

    return (
        <div
            ref={refEl}
            css={csss}
            {...props}
        >
            <DraggableListItemContext.Provider value={context}>
                {children}
            </DraggableListItemContext.Provider>
        </div>
    )
}
