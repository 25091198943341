export const base64FromBlob = async (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(btoa(reader.result))
        reader.readAsBinaryString(blob)
    })
}

export const chooseFiles = async (options) => {
    try {
        const fileHandles = await window.showOpenFilePicker({
            excludeAcceptAllOption: true,

            types: [
                {
                    description: '*.*',
                    accept: {}
                },
            ],

            ...options,
        })

        const files = await Promise.all(
            fileHandles.map((fileHandle) => fileHandle.getFile())
        )

        return files
    }
    catch (err) {
        if ('AbortError' === err.name) {
            return []
        }
        else {
            throw err
        }
    }
}

export const dataUrlFromBlob = async (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}

chooseFiles.image = (options) => {
    return chooseFiles({
        types: [
            {
                description: 'Images',

                accept: {
                    'image/*': [
                        '.png',
                        '.gif',
                        '.jpeg',
                        '.jpg',
                    ]
                }
            },
        ],

        ...options,
    })
}

export const downloadFile = (data, fileName) => {
    const url = URL.createObjectURL(data)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
}

export const fileFromClipboard = async (type, filename) => {
    const items = await navigator.clipboard.read()

    const item = (() => {
        for (const item of items) {
            if (
                // MacOS 某些版本截图产生的数据没有 type
                0 === item.types.length ||
                item.types.includes(type)
            ) {
                return item
            }
        }
    })()


    if (! item) {
        throw new Error('未找到数据')
    }

    const blob = await item.getType(type)
    return new File([blob], filename, {type})
}

export const writeFileToDisk = async (data, options) => {
    try {
        const fileHandle = await window.showSaveFilePicker(options)
        const ws = await fileHandle.createWritable()
        await ws.write(data)
        await ws.close()
    }
    catch (err) {
        if ('SecurityError' === err.name) {
            downloadFile(data, options.suggestedName)
        }
        else if ('AbortError' !== err.name) {
            throw err
        }
    }
}

export const getExt = (fileName) => {
    const ext = fileName.replace(/^.*\.([^.]*)$/, '$1')
    return ext === fileName ? '' : ext
}
