import {useEffect} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

const source = {}

export const eventBus = {
    publish: (...args) => publish(source, ...args),
    subscribe: (...args) => subscribe(source, ...args),
    unsubscribe: (...args) => unsubscribe(source, ...args),
}

export const useEventBus = (eventType, callback) => {
    useEffect(
        () => {
            eventBus.subscribe(eventType, callback)
            return () => eventBus.unsubscribe(eventType, callback)
        },

        [eventType, callback]
    )
}
