import {useEffect, useRef} from 'react'
import {nanoid} from 'nanoid'

const indexesOfColumns = (elTable) => {
    const indexes = new Map

    for (const t of elTable.querySelectorAll('thead [data-column-key]')) {
        const {columnKey} = t.dataset
        const th = t.closest('th')
        const i = Array.prototype.indexOf.call(th.parentElement.children, th)
        indexes.set(columnKey, i)
    }

    return indexes
}

export default (table) => {
    const refStyleSheet = useRef()
    const refStyles = useRef(new Map)
    const {config, configurable, dom: elTable} = table

    useEffect(
        () => {
            if (! elTable) {
                return
            }

            refStyleSheet.current = new CSSStyleSheet
            document.adoptedStyleSheets.push(refStyleSheet.current)

            const {dataset} = elTable

            if (! dataset.tableId) {
                dataset.tableId = nanoid()
            }

            return () => {
                const i = document.adoptedStyleSheets.indexOf(
                    refStyleSheet.current
                )

                document.adoptedStyleSheets.splice(i, 1)
            }
        },

        [elTable]
    )

    useEffect(
        () => {
            if (! (configurable && elTable)) {
                return
            }

            const styles = refStyles.current
            const styleSheet = refStyleSheet.current
            const indexes = indexesOfColumns(elTable)

            const getSelector = id => {
                const {tableId} = elTable.dataset
                const i = indexes.get(id)
                return `[data-table-id="${tableId}"] td.ant-table-cell:nth-child(${i + 1})`
            }

            const createRule = (id, backgroundColor, color) => {
                const props = []

                if (backgroundColor) {
                    props.push(`background-color: ${backgroundColor};`)
                }

                if (color) {
                    props.push(`color: ${color};`)
                }

                const selector = getSelector(id)

                styleSheet.insertRule(
                    `${selector} {${props.join('\n')}}`,
                    styleSheet.cssRules.length
                )

                styles.set(id, {backgroundColor, color})
            }

            const deleteRule = id => {
                const selector = getSelector(id)

                const i = Array.prototype.findIndex.call(
                    styleSheet.cssRules,
                    e => selector === e.selectorText
                )

                styleSheet.deleteRule(i)
                styles.delete(id)
            }

            for (const column of config.columns) {
                const {backgroundColor, color, id} = column
                const style = styles.get(id)

                // 设置了颜色
                if (backgroundColor || color) {
                    // 之前设置过颜色
                    if (style) {
                        // 颜色改变
                        if (
                            style.backgroundColor !== backgroundColor ||
                            style.color !== color
                        ) {
                            deleteRule(id)
                            createRule(id, backgroundColor, color)
                        }
                    }
                    // 之前未设置过颜色
                    else {
                        createRule(id, backgroundColor, color)
                    }
                }
                // 未设置颜色
                else {
                    // 之前设置过颜色
                    if (style) {
                        deleteRule(id)
                    }
                }
            }
        },

        [config, configurable, elTable]
    )
}
