export default (path, params = {}, target, features) => {
    const refinedParams = Object.fromEntries(
        Object.entries(params).filter(
            ([key, value]) => ! (
                undefined === key ||
                null === key ||
                undefined === value ||
                null === value
            )
        )
    )

    const qs = String(new URLSearchParams(refinedParams))
    const url = qs ? `${path}?${qs}` : path
    return window.open(url, target, features)
}
